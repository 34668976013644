import { useState, useEffect } from "react";
import useDiffLang from "../contexts/langContext";

import StarIcon from "./Svg/StarIcon";

function ChatReview({
  submitted,
  setSubmited,
  reviewRate,
  setReviewRate,
  countdown,
  setCountdown,
  showEmailBox,
}) {
  const [hoveredRating, setHoveredRating] = useState(0);

  const { defaultLang } = useDiffLang();

  useEffect(() => {
    let timer;
    if (submitted && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [submitted, countdown]);

  const handleMouseEnter = (rating) => {
    setHoveredRating(rating);
  };

  const handleMouseLeave = () => {
    setHoveredRating(0);
  };

  const handleClick = (event, rating) => {
    event.stopPropagation();
    setReviewRate(rating);
    setSubmited(true);
  };

  return (
    <div className="lcb_review-container">
      <div>
        <h3 className="lcb_review-title">{defaultLang.review_title}</h3>
        <div className="lcb_review-rate-container">
          <div className="lcb_review-rate">
            {[1, 2, 3, 4, 5].map((id) => (
              <div
                key={id}
                className="lcb_review-stars"
                data-rate={id}
                style={{
                  color: `${
                    id <= (hoveredRating || reviewRate) ? "#e5e572" : "#b2b2b2"
                  }`,
                }}
                onMouseEnter={() => handleMouseEnter(id)}
                onMouseLeave={handleMouseLeave}
                onClick={(event) => handleClick(event, id)}
              >
                <span style={{ pointerEvents: "none" }}>
                  <StarIcon />
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      {submitted && !showEmailBox && (
        <div className="lcb_review-feedback">
          <p>{defaultLang.end_saluation}</p>
          <p>{defaultLang.autoclose_message}:</p>
          <div>
            <span className="lcb_review-close-counter">{countdown}</span>{" "}
            {defaultLang.seconds}
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatReview;
