import React, { useState, useEffect, useRef } from "react";
import CrossIcon from "./Svg/CrossIcon";
import FileIcon from "./Svg/FileIcon";
import SendIcon from "./Svg/SendIcon";
import Headphones from "./Svg/Headphones";
import HangUp from "./Svg/HangUp";
import useDiffLang from "../contexts/langContext";
import LoadingIcons from "react-loading-icons";
import {
  LiveKitRoom,
  useConnectionState,
  useLocalParticipant,
  useRemoteParticipants,
  useTracks,
} from "@livekit/components-react";
import { TrackToggle, RoomAudioRenderer } from "@livekit/components-react";
import { Track, RoomEvent } from "livekit-client";
import { AgentMultibandAudioVisualizer } from "./visualization/AgentMultibandAudioVisualizer";
import { useMultibandTrackVolume } from "./hooks/useTrackVolume";

export const InputMessage = ({
  showError,
  showConsentMsg,
  handleAddMessage,
  inputMessage,
  setInputMessage,
  isFullScreen,
  htmlElRef,
  isFetching,
  fileInputRef,
  connectToLiveKitAudio,
  token,
  url,
  widgetInfo,
  setEndChat,
  messageCount = 1,
  setSaveEndChat,
}) => {
  const [prevImg, setPrevImg] = useState(false);
  const [prevImgSrc, setPrevImgSrc] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [isLiveKitConnected, setIsLiveKitConnected] = useState(false);

  const { defaultLang } = useDiffLang();

  const onSubmitHandler = (e) => {
    e?.preventDefault();
    if (inputMessage || prevImgSrc) {
      setIsUploading(true);
      handleAddMessage(inputMessage, prevImgSrc);
      setInputMessage("");
      closeImgPrv();
      htmlElRef.current.style.height = "44px";
      setTimeout(() => {
        setIsUploading(false);
      }, 3000);
    }
  };

  const previewPhoto = (e) => {
    setPrevImg(true);
    const file = e.target.files[0];
    if (file) {
      setPrevImgSrc(file);
      if (!inputMessage) {
        setInputMessage("");
      }
      htmlElRef.current.focus();
    }
  };

  const closeImgPrv = () => {
    setPrevImg(false);
    setPrevImgSrc("");
    document.getElementById("file-input").value = "";
  };

  const calculateHeight = () => {
    if (htmlElRef.current) {
      htmlElRef.current.style.height = "auto";
      htmlElRef.current.style.height = `${
        htmlElRef.current.scrollHeight - 8
      }px`;
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      onSubmitHandler(event);
    }
  };

  const LiveKitComponent = () => {
    const { localParticipant } = useLocalParticipant();
    const remoteParticipants = useRemoteParticipants();
    const tracks = useTracks();

    const agentParticipant = remoteParticipants.find((p) => p.isAgent);
    const agentAudioTrack = tracks.find(
      (trackRef) =>
        trackRef.publication.kind === Track.Kind.Audio &&
        trackRef.participant.isAgent
    );

    const subscribedVolumes = useMultibandTrackVolume(
      agentAudioTrack?.publication?.track,
      5
    );

    useEffect(() => {
      async function enableAudio() {
        await localParticipant.setMicrophoneEnabled(true);
      }
      if (localParticipant) {
        enableAudio();
      }
    }, [localParticipant]);

    const handleDisconnect = () => {
      if (localParticipant && localParticipant.room) {
        localParticipant.room.disconnect();
      }
      setIsLiveKitConnected(false);
    };

    useEffect(() => {
      if (localParticipant && localParticipant.room) {
        const room = localParticipant.room;
        room.on(RoomEvent.Disconnected, handleDisconnect);
        return () => {
          room.off(RoomEvent.Disconnected, handleDisconnect);
        };
      }
    }, [localParticipant]);

    return (
      <div className="lcb_livekit-container">
        <RoomAudioRenderer />
        <div className="lcb_audio-visualizer-wrapper">
          <AgentMultibandAudioVisualizer
            state="speaking"
            barWidth={30}
            minBarHeight={30}
            maxBarHeight={150}
            accentColor={"purple"}
            accentShade={500}
            frequencies={subscribedVolumes}
            borderRadius={12}
            gap={16}
          />
        </div>
        <TrackToggle
          className="lcb_track-toggle"
          source={Track.Source.Microphone}
          showIcon={true}
          initialState={true}
          onChange={(enabled, isUserInitiated) => {
            console.log(
              `Microphone ${
                enabled ? "enabled" : "disabled"
              }, user initiated: ${isUserInitiated}`
            );
          }}
          onDeviceError={(error) => {
            console.error("Microphone device error:", error);
          }}
        />
        <button onClick={handleDisconnect} className="lcb_disconnect-button">
          <HangUp />
        </button>
      </div>
    );
  };

  return (
    <div className="lcb_input-message">
      {messageCount > 2 && (
        <div className="lcb_review-actions">
          <button
            className="lcb_review-submit"
            onClick={() => setEndChat(true)}
          >
            {defaultLang.close_btn}
          </button>
          <button
            className="lcb_review-submit-save"
            onClick={() => setSaveEndChat(true)}
          >
            {defaultLang.save_close}
          </button>
        </div>
      )}
      {prevImg && prevImgSrc && (
        <div
          className={`lcb_img-prev ${
            isFullScreen ? "lcb_img-prev-full-screen" : ""
          }`}
        >
          <span className="cross-icon" onClick={closeImgPrv}>
            <CrossIcon />
          </span>
          <img src={URL?.createObjectURL(prevImgSrc)} alt="Preview" />
        </div>
      )}
      <form
        onSubmit={onSubmitHandler}
        className="lcb_inputText"
        style={{
          marginBottom:
            showConsentMsg && !localStorage.getItem("lcb-consent-msg-shown")
              ? 0
              : 12,
        }}
      >
        {widgetInfo.voice && token !== null && isLiveKitConnected ? (
          <LiveKitRoom
            token={token}
            serverUrl={url}
            options={{ autoSubscribe: true }}
          >
            <LiveKitComponent />
          </LiveKitRoom>
        ) : (
          <>
            {widgetInfo.voice && (
              <button
                type="button"
                onClick={() => {
                  connectToLiveKitAudio();
                  setIsLiveKitConnected(true);
                }}
                className="lcb_start-audio-session lcb_headphones"
                disabled={isFetching || showError}
              >
                <Headphones />
              </button>
            )}

            {isUploading ? (
              <div className="lcb_uploading-indicator">
                <LoadingIcons.TailSpin stroke="currentColor" />
              </div>
            ) : (
              <label className="lcb_attachFile" htmlFor="file-input">
                <input
                  disabled={isFetching || showError}
                  ref={fileInputRef}
                  id="file-input"
                  type="file"
                  accept="image/png, image/gif, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv"
                  onChange={previewPhoto}
                />
                <FileIcon />
              </label>
            )}

            <textarea
              placeholder={defaultLang.send_msg}
              onChange={(e) => {
                setInputMessage(e.target.value);
              }}
              onKeyDown={handleKeyDown}
              name="message"
              ref={htmlElRef}
              disabled={isFetching || showError}
              value={inputMessage}
              onInput={calculateHeight}
              style={{
                margin:
                  showConsentMsg &&
                  !localStorage.getItem("lcb-consent-msg-shown")
                    ? "10px 3px"
                    : "16px 10px 16px 3px",
              }}
            ></textarea>
            <button
              type="submit"
              className={`lcb_sendButton ${
                isFullScreen ? "lcb_manageIcon" : ""
              } ${inputMessage || prevImgSrc ? "highlight" : ""}`}
              style={{
                padding:
                  showConsentMsg &&
                  !localStorage.getItem("lcb-consent-msg-shown")
                    ? "3px"
                    : "7px 8px",
              }}
              disabled={isFetching}
            >
              <SendIcon />
            </button>
          </>
        )}
      </form>
      {showConsentMsg && !localStorage.getItem("lcb-consent-msg-shown") && (
        <div
          className={`lcb-consent-msg ${isFullScreen ? "in-full-screen" : ""}`}
        >
          <span>{defaultLang.consent}</span>
        </div>
      )}
    </div>
  );
};
