export const CONSTANTS = {
  HEADER: {
    botLable: "BOT",
  },
  EMAIL_MODAL: {
    // title: "Save your Chats",
    // btnLable: "SUBMIT",
    reqEmail: "Email is required.",
    validEmail: "Email id is not valid.",
  },
  ERROR_MESSAGE: "There is some error, Please try again.",
  PRODUCT_DETAILS: {
    detailsBtnLable: "View Details",
  },
  TOOLTIPS: {
    // reset: "Reset Chat",
    // save: "Save Chat",
    // dark: "Switch Mode",
    // exitFullScreen: "Enter full screen mode",
    // enterFullScreen: "Exit full screen mode",
    // hideChat: "Hide Chat",
    // showChat: "Show Chat",
    goodFeedback: "Good Response",
    badFeedback: "Bad Response",
  },
  // INPUT: {
  //   placeholder: "Ask anything...",
  // },
  // CONSENT_MESSAGE: {
  //   msg: "By chatting, you consent to this chat being stored according to our privacy policy.",
  // },
};
export const LOCALE_STRINGS = {
  consent:
    "By chatting, you consent to this chat being stored according to our privacy policy.",
  send_msg: "Ask anything",
  refresh_btn: "Reset chat",
  save_btn: "Save chat",
  switch_btn: "Switch mode",
  fs_open: "Enter full screen mode",
  fs_close: "Exit full screen mode",
  widget_open: "Open chat",
  widget_close: "Close chat window",
  save_title: "Get chat to your email",
  save_placeholder: "Enter your email",
  save_submit: "Submit",
  close_btn: "Close chat",
  save_close: "Close and save",
  review_title: "How satisfied are you with the chat?",
  end_saluation: "Thank you for your feedback.",
  autoclose_message: "The Chat will automatically close in",
  seconds: "seconds",

  send_btn: "Send",
};
